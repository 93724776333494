import React from 'react'
import { Route,Switch, Redirect } from 'react-router-dom'
import Home from '../views/index.jsx';
import Login from '../views/login.jsx';
import News from '../views/news.jsx';
import Help from '../views/help.jsx';
import Settings from '../views/settings.jsx';
import Subscription from '../views/subscription.jsx';
import Blogdetails from '../views/blog-details.jsx';
import Privacy from '../views/privacy-policy.jsx';
import Terms from '../views/terms-and-conitions.jsx';
import Forgot from '../views/forgot.jsx';
import ResetPassword from '../views/reset.jsx';
import CheckoutForm from '../views/checkoutForm.jsx';

import PrivateRoutes from './PrivateRoutes.jsx'
import PublicRoute from './PublicRoutes.jsx'

const Routes = () => (	

    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact>
        <Home />
      </Route>        
      <PublicRoute path={`${process.env.PUBLIC_URL}/login`} component={Login} exact />   
      <Route path={`${process.env.PUBLIC_URL}/blog-details/:slug`} exact>
        <Blogdetails />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/forgot-password`} exact>
        <Forgot />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/reset-password/:token/:email`} exact>
        <ResetPassword />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/terms-and-conditions`} exact>
        <Terms />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} exact>
        <Privacy />
      </Route> 
      <Route path={`${process.env.PUBLIC_URL}/help`} exact>
        <Help />
      </Route> 
      <PrivateRoutes path={`${process.env.PUBLIC_URL}/news`} component={News} exact />
      <PrivateRoutes path={`${process.env.PUBLIC_URL}/settings`} component={Settings} exact />
      <PrivateRoutes path={`${process.env.PUBLIC_URL}/subscription`} component={Subscription} exact />
      <Redirect to={`${process.env.PUBLIC_URL}/`} />
    </Switch>
)

export default Routes