import React, { Component, Fragment ,useState,useEffect } from 'react'
import { Helmet } from 'react-helmet'
// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

import classnames from 'classnames';
import newsFeed from './newsfeed.js';

// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// News page images
import aboutvideo from "../assets/videos/about-video.mp4";
import newsimg from "../assets/images/news-img.png"
// News page images

import { ToastContainer,toast } from 'react-toastify';
import http from '../httpService/httpService'
import '../../node_modules/react-toastify/dist/ReactToastify.css'

import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
    NavLink,
    TabContent, TabPane, Modal, ModalBody
  } from 'reactstrap';
import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import InnerMenu from './userLinks.jsx';
import Player from './Player.jsx';

import Posts from "./Posts";

const postsPerPage = 4;
let arrayForHoldingPosts = [];

const TITLE = 'News | Trading Floor Audio'
const News   = (props) => {
    

    const [ expired , setExpired ] = useState(1);
    useEffect(() => {        
        
        if(!props.fetched) {
            const token = localStorage.getItem('marketAuthToken');
            http.get(process.env.REACT_APP_API_URL + '/user-view-profile',{
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then((response) => {
                setExpired(response.data.data.expired);
                newsFeed()                                        
            });
        }        

    }, []);
  

    const [isOpen, setIsOpen] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

	const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(4);
    const [spost, sspost] = useState(4);

    const [activeTab, setActiveTab] = useState('latest');
    const [category, setCategory] = useState([]);
    const [news, setNew] = useState([]);

    const loopWithSlice = (start, end) => {        
        const slicedPosts = news.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
    };


    const toggletab = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
    
    const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
        sspost(next + postsPerPage);
    };

         
    return (
        <Fragment>

        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>

        {/* Header */}

        <Navigation />

        {/* Header */}

        {/* Dashboard-header */}

        <div className="dashboard-header-sec">
            <div className="dashboard-header">                
                {loaded ? <Player /> : ''}                
            </div>
            <div className="dahboard-nav-sec">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                        <Col md="6">
                            <InnerMenu/>
                        </Col>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <video autoPlay loop="" muted="muted" data-origwidth="0" data-origheight="0"  loop playsInline>
                    <source src={aboutvideo} type="video/mp4" />
             </video>
        </div>

        {/* Dashboard-header */}

        {/* All News Sectiom */}

        <div className="all-news-section">
            <Container>
                <div className="access-squawk"> <a href="https://www.livesquawk.com/partner_demos/tradingfloor_demo.php" target="_blanl" class="common-btn  btn nav-link">Access Squawk here!</a></div>
                <h2>Market News</h2>
                { expired == 0 ? <div id="livesquawk_messenger_outer">
            </div> : '' } 
            </Container>
        </div>

        {/* All News Sectiom */}

       
 
         {/* Footer */}
 
         <FooterSection/>    
         
 
         {/* Footer */}
 
 
         </Fragment>)
}
 
 export default News