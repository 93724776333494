import React,{useState,useRef,useEffect} from 'react'
import { Container,Row, Col,NavLink,Modal, ModalBody} from 'reactstrap';
import { Link } from 'react-router-dom'
import footerlogo from "../assets/images/footer-logo.svg";
import { history } from '../index';
import { useLocation } from 'react-router-dom';
import http from '../httpService/httpService'

const FooterSection = (props) =>{
    const location = useLocation();
    const redirectPage = (redTo,id) => {
            
        var itemArr = [process.env.REACT_APP_PREFIX+"news",process.env.REACT_APP_PREFIX+"subscription",process.env.REACT_APP_PREFIX+"settings"];
       
        if( itemArr.indexOf(location.pathname) > -1){
            setModal(true);
            setredToPage(redTo);
        }else{

            if(props.onClickMenu !== undefined && id != ''){
                props.onClickMenu(id);
            }else{
                window.location.href = redTo;
            }
            // console.log(location.pathname);
            //window.location.href = redTo;
            
        }
        

    }
    const pagemodal = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const [redToPage, setredToPage] = useState(`${process.env.PUBLIC_URL}`);

    const [links, setLinks] = useState({ 
        fb : 'https://www.facebook.com',
        ln : 'https://www.linkedin.com',
        tw : 'https://www.twitter.com',
        ft:`The Trading Floor Audio lorem ipsum has been the industry's standard dummy text in the United Kingdom since the 2010 with the global news.`
    });

    useEffect(() => {
        if(!props.fetched) {

            http.get(process.env.REACT_APP_API_URL + '/site-config')
            .then((response) => {
              
               setLinks({
                   fb : response.data.data[5].facebook,
                   tw : response.data.data[6].twitter,
                   ln : response.data.data[7].linkedin,
                   ft : response.data.data[8].footer_text,
               });
            });
        }
    }, []);

    return (

        <footer>
             <Container>
                 <Row>
                     <Col md="6">
                         <div className="footer-left-sec">
                             <ul className="footer-menu">
                                 <li>
                                     <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}`,'')}>HOME</NavLink>
                                 </li>
                                 {/* <li>
                                    <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#pricing`,'pricing')} >PRICING</NavLink>
                                 </li> */}
                                 <li>
                                    <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#blog`,'blog')}>BLOG</NavLink>
                                 </li>
                             </ul>
                             <div className="footer-desc-sec">
                                 <img  src={footerlogo}/>
                                 <div className="footer-desc">
                                     <p>{ links.ft }</p>
                                 </div>
                             </div>
                         </div>
                     </Col>
                     <Col md="6">
                         <div className="footer-right-sec">
                             <ul className="footer-right-menu">
                                 <li>
                                     <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/privacy-policy`,'')}>PRIVACY</NavLink>
                                 </li>
                                 <li>
                                     <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/terms-and-conditions`,'')} >TERMS</NavLink>
                                 </li>
                                 <li>
                                     <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/help`,'')} >HELP</NavLink>
                                 </li>
                                 <li>
                                     <NavLink  onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#contact`,'contact')}>CONTACT</NavLink>
                                 </li>
                             </ul>
                             <ul className="social-links">
                                 <li>
                                     { 
                                        (links.ln !== '#') ? <a href={links.ln} target='_blank'><i className="fa fa-linkedin" aria-hidden="true"></i></a> : <a href='#' ><i className="fa fa-linkedin" aria-hidden="true"></i></a> 
                                     }
                                     
                                 </li>
                                 <li>
                                     { 
                                        (links.fb !== '#') ? <a href={links.fb} target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a> : <a href='#' ><i className="fa fa-facebook" aria-hidden="true"></i></a> 
                                     }
                                 </li>
                                 <li>
                                     
                                     { 
                                        (links.tw !== '#') ? <a href={links.tw} target='_blank'><i className="fa fa-twitter" aria-hidden="true"></i></a> : <a href='#' ><i className="fa fa-twitter" aria-hidden="true"></i></a> 
                                     }
                                 </li>
                             </ul>
                         </div>
                     </Col>
                 </Row>
             </Container>
             <Modal isOpen={modal} toggle={pagemodal} className="page-modal" >
                    <ModalBody>
                        <h3>Are You Sure ?</h3>
                        <p>Are you sure you want to navigate away from this page? Leaving will STOP your in-page audio-connection... </p>
                         <NavLink className="primary-btn btn" href={ redToPage }> Leave Page Anyway</NavLink>
                        <button className="primary-btn btn" onClick={pagemodal} >Stay on This Page</button>
                    </ModalBody>
                </Modal>
         </footer>
        )
    }
 export default FooterSection