import React, { Component, Fragment ,useState,useEffect } from 'react'
import { Helmet } from 'react-helmet'
// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

import classnames from 'classnames';


// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// News page images
import aboutvideo from "../assets/videos/about-video.mp4";

// News page images
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
    NavLink,
    Form, FormGroup, Input
} from 'reactstrap';
import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import InnerMenu from './userLinks.jsx';
import http from '../httpService/httpService'
import { verifyEmail ,verifyPassword,verifyMobileNumber } from '../helper';
import { ToastContainer,toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import Loader from '../components/loader.jsx';
import Player from './Player.jsx';
import loadPlayer from './loadPlayer.js';
const TITLE = 'Settings | Trading Floor Audio'
const Settings   = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [activeTab, setActiveTab] = useState('1');

    const toggletab = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const [ account , setAccount ] = useState( { email : '', full_name: '',contact_no:''} );
    const [ errors , setErrors] = useState( { errEmail : '',errFullname : '',errContactNo : ''} );
    const [ loader , setLoader] = useState(false);
    const [loaded, setLoaded] = useState(true);

    useEffect(() => {
        /*loadPlayer(() => {
          setLoaded(true);
        });*/
        
        if(!props.fetched) {
             window.scrollTo(0, 0)
            const token = localStorage.getItem('marketAuthToken');
            http.get(process.env.REACT_APP_API_URL + '/user-view-profile',{
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then((response) => {

                let newData = { ...account };                
                newData['email']=response.data.data.email;
                newData['full_name']=response.data.data.full_name;
                newData['contact_no']=response.data.data.contact_no;
                setAccount(newData);                
            });
        }

        if(!props.fetched) {

          // const script = document.createElement("script");
          // script.src = "https://code.jquery.com/jquery-3.2.1.min.js";
          // script.async = true;
          // document.body.appendChild(script);

          // const script1 = document.createElement("script");
          // script1.src = "//www.livesquawk.com/partner_player_only/js/include_tms_webrtc_only.js?v=3";
          // script1.async = false;
          // document.body.appendChild(script1);
        }

    }, []);

    const handleChange = (e) => {

        let newAcc = { ...account };
        const { name , value  } = e.target;
        newAcc[name]=value;
        setAccount(newAcc);
     
    }

     const validateAccount = () => {
           
        const errors = {};
        // Old Password Validation
        if(account.full_name == ''){
          errors.errFullname = 'Full name is required field';
        }

        if(account.contact_no == ''){
          errors.errContactNo = 'Contact number is required field';
        }else if(!verifyMobileNumber(account.contact_no)){
          errors.errContactNo = 'Please enter contact number between 6 to 16 number';
        } 
        
        setErrors(errors);
        return Object.keys(errors).length === 0 ? null : errors;

    }

    const handleSubmitAccount = e => {

        e.preventDefault();
        let full_name =account.full_name; 
        let email =account.email; 
        let contact_no =account.contact_no; 
        
        if( validateAccount() === null){
            setLoader(true);
            const token = localStorage.getItem('marketAuthToken');
            http.post(process.env.REACT_APP_API_URL+'/user-edit-profile', { full_name , email, contact_no},{
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then((response) => {   
                
                setLoader(false);      
                toast.success(response.data.meta.message);
              
            })
            .catch((error) => {
              setLoader(false);
              if(error.response && error.response.status != 200){
                 toast.error(error.response.data.meta.message);
              }
            });
        }
       
    }

    /* Change Password */
    
    const [ passwordData , setPasswordData ] = useState( { old_password : '' , password:'', password_confirmation:'' } );
    const [ passwordErr , setPasswordErr ] = useState( { err_old_password : '' , err_password:'', err_password_confirmation:'' } );
    
    const handleChangePassword = (e) => {

        const { name , value  } = e.target;
        passwordData[name]=value;
        setPasswordData(passwordData);
    }

    const validatePassword = () => {
           
        const errors = {};
        // Old Password Validation
        if(passwordData.old_password == ''){
          errors.err_old_password = 'Account password is required field';
        }else if(!verifyPassword(passwordData.old_password)){
          errors.err_old_password = 'Please enter password between 6 to 15 character';
        } 

        // Old Password Validation
        if(passwordData.password == ''){
          errors.err_password = 'Password is required field';
        }else if(!verifyPassword(passwordData.password)){
          errors.err_password = 'Please enter password in between 6 to 15 character';
        }

        // Password Validation
        if(passwordData.password_confirmation == ''){
          errors.err_password_confirmation = 'Confirm Password is required field';
        }else if(!verifyPassword(passwordData.password_confirmation)){
          errors.err_password_confirmation = 'Please enter password in between 6 to 15 character';
        }else if(passwordData.password !== passwordData.password_confirmation){
            errors.err_password_confirmation = 'Password and  confirm password should be same';
        } 
        
        setPasswordErr(errors);
        return Object.keys(errors).length === 0 ? null : errors;

    }

    const handleSubmitPassword = e => {

        e.preventDefault();
        let old_password =passwordData.old_password; 
        let password =passwordData.password; 
        let password_confirmation =passwordData.password_confirmation; 
        
        if( validatePassword() === null){
            setLoader(true);
            const token = localStorage.getItem('marketAuthToken');
            http.post(process.env.REACT_APP_API_URL+'/change-password', { old_password , password, password_confirmation},{
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then((response) => {   
                document.getElementById("change-password-form").reset();
                setLoader(false);      
                toast.success(response.data.meta.message);
              
            })
            .catch((error) => {
              setLoader(false);
              if(error.response && error.response.status != 200){
                 toast.error(error.response.data.meta.message);
              }
            });
        }
       
    }

    return (
            
        <Fragment>

        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>

        {loader && <Loader />} 
        {/* Header */}
         
        <Navigation />

        {/* Header */}

        {/* Dashboard-header */}

        <div className="dashboard-header-sec">
            <div className="dashboard-header">
                {loaded ? <Player /> : ''}
                {/*<div id="livesquawk_player_outer">
                </div>
                 <Container>
                    <Row>
                        <Col md="3">
                            <div className="volume-section">
                                <p>Volume Adjust</p>
                            </div>
                        </Col>
                        <Col md="6" className="middle-header">
                            <div className="on-air-labels">
                                <span>ON AIR</span>
                                <span className="on-air-icon"></span>
                                <span>ON AIR</span>
                            </div>
                        </Col>
                        <Col md="3">
                        
                        </Col>
                    </Row>
                </Container> */}
            </div>
            <div className="dahboard-nav-sec">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                        <Col md="6">
                            <InnerMenu/>
                        </Col>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <video autoPlay loop="" muted="muted" data-origwidth="0" data-origheight="0" loop playsInline>
                    <source src={aboutvideo} type="video/mp4" />
             </video>
        </div>

        {/* Dashboard-header */}

        {/* Account Settings Section */}

        <div className="acoount-settings-sec">
            <Container>
                <h2>Account Settings</h2>
                <p>Manage your Account Settings</p>
                <div className="account-info">
                    <h4>Contact Info</h4>
                    <Form className="login-form" onSubmit=  { handleSubmitAccount }>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <label>FULL NAME</label>
                                    <input type="text" name="full_name" value={account.full_name} onChange={ handleChange } />
                                    { errors.errFullname && <p className="error-text"> <em> { errors.errFullname } </em></p> }
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                 <FormGroup>
                                    <label>CONTACT EMAIL</label>
                                    <input type="email" name="email" defaultValue={account.email}/>
                                    { errors.errEmail && <p className="error-text"> <em> { errors.errEmail } </em></p> }
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <label>TELEPHONE</label>
                                    <input type="text" name="contact_no" value={account.contact_no} onChange={ handleChange }/>
                                    { errors.errContactNo && <p className="error-text"> <em> { errors.errContactNo } </em></p> }
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* <div className="remember-checkbox">
                            <input type="checkbox" name="remember-input" />
                            <span><i class="fa fa-check" aria-hidden="true"></i></span>
                            <p>I agree to the <a href="#">terms of use</a></p>
                        </div> */}
                        <button type="submit" className="btn common-btn" >CONFIRM CHANGES</button>
                    </Form>
                    <h4>Account Info</h4>
                    <Form className="login-form" onSubmit= { handleSubmitPassword } id="change-password-form">
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <label>ACCOUNT PASSWORD</label>
                                <input type="password" name="old_password" onChange={handleChangePassword}/>
                                { passwordErr.err_old_password && <p className="error-text"> <em> { passwordErr.err_old_password } </em></p> }
                            </FormGroup>
                        </Col>
                        <Col md="4">
                             <FormGroup>
                                <label>CHANGE PASSWORD</label>
                                <input type="password" name="password" onChange={handleChangePassword}/>
                                { passwordErr.err_password && <p className="error-text"> <em> { passwordErr.err_password } </em></p> }
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <label>RETYPE</label>
                                <input type="password" name="password_confirmation" onChange={handleChangePassword}/>
                                { passwordErr.err_password_confirmation && <p className="error-text"> <em> { passwordErr.err_password_confirmation } </em></p> }

                            </FormGroup>
                        </Col>
                    </Row>
                    {/* <div className="remember-checkbox">
                            <input type="checkbox" name="remember-input" />
                            <span><i class="fa fa-check" aria-hidden="true"></i></span>
                            <p>I agree to the <a href="#">terms of use</a></p>
                        </div> */}
                    <button type="submit" className="btn common-btn" >CONFIRM CHANGES</button>
                    </Form>
                </div>
            </Container>

        </div>

        {/* Account Settings Section */}
 
         {/* Footer */}
 
         <FooterSection/>   
         
 
         {/* Footer */}
 
 
         </Fragment>)
}
 
 export default Settings