import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorker from './serviceWorker.js';
import { Router,BrowserRouter } from 'react-router-dom'
import Routes from './routes/index.jsx'
import { createBrowserHistory } from 'history'
import App from './App';
import Loader from './components/loader.jsx';

import reducers from './reducers'
import { createStore, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { ToastContainer,toast } from 'react-toastify';
import http from './httpService/httpService';

// const componentEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// export const store = createStore(reducers, {}, componentEnhancers(applyMiddleware(ReduxThunk)))
export const store = createStore(reducers, {}, (applyMiddleware(ReduxThunk)))

if (typeof navigator !== 'undefined') {
  const hasRendered = false
  const isCookieEnabled = navigator.cookieEnabled
  if (isCookieEnabled) {
    ReactDOM.render(<Loader />, document.getElementById('root'))
  }
}

export const history = createBrowserHistory()
const jsx = (

	<Suspense fallback={<Loader />}>
		<Provider store={store}>
			<Router history={history}>
				<Routes />
			</Router>
			<ToastContainer />
		</Provider>
	</Suspense>

)


const renderApp = () => {
  if (navigator.cookieEnabled) {
    ReactDOM.hydrate(jsx, document.getElementById('root'));
  }
}

const token = localStorage.getItem('marketAuthToken');
if (token) {
  http.get(process.env.REACT_APP_API_URL+'/user-view-profile', { headers: { Authorization: `Bearer ${token}` } }).then((response) => {
    store.dispatch({
      type: 'USER_PROFILE',
      payload: {
        data: response.data.data,
        token
      }
    })
    renderApp()
    // if(response.data.data.is_active == 'n'){
    //     const token = localStorage.getItem('marketAuthToken');
    //     localStorage.removeItem('marketAuthToken')
    //     http.post(process.env.REACT_APP_API_URL + '/logout', {}, {
    //       headers: {
    //         'Authorization': `Bearer ${token}`
    //       }
    //     }).then((response) => {      
    //       history.push(process.env.PUBLIC_URL + '/login');

    //     }).catch((error) => {        
    //        history.push(process.env.PUBLIC_URL + '/login');
    //     });
    // }
    
    
    
  }).catch((error) => {
    renderApp()
  })
} else {
  renderApp()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
