import React, { Component, Fragment ,useState , useEffect,useMemo } from 'react'
import { Helmet } from 'react-helmet'

// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// Login page images
import stripimg from "../assets/images/strip-img.png"
// Login page images
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button, Form, FormGroup, Input, Modal, ModalBody
} from 'reactstrap';
import { Link,Redirect,withRouter } from 'react-router-dom';
import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import { verifyEmail ,verifyPassword } from '../helper';
import { ToastContainer,toast } from 'react-toastify';
import http from '../httpService/httpService'
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import { store } from '../store.js'
import Loader from '../components/loader.jsx';
import { connect } from 'react-redux';
import { compose } from 'redux';

const useOptions = () => {
  
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    })    
  );

  return options;
};


const TITLE = 'Login | Trading Floor Audio';
const Login   = (props) => {    

    const [ isOpen, setIsOpen ] = useState(false);
    const [ account , setAccount ] = useState( { email : '', password: ''} );
    const [ errors , setErrors] = useState( { errEmail : '',errPasword : ''} );
    const [ loader , setLoader] = useState(false);
    const [ subValue , setsubValue] = useState({ days : '',amt1:'',amt2:'' });
    const toggle = () => setIsOpen(!isOpen);

    const signupmodal1 = () => setModal1(!modal1);
    const signupmodal2 = () => setModal2(!modal2);
    const signupmodal3 = () => setModal3(!modal3);

    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);


    const validate = () => {
           
        const errors = {};
         // Email Validation
        if(account.email === ''){
          errors.errEmail = 'Email is required field';
        }else if(!verifyEmail(account.email)){
          errors.errEmail = 'Please enter valid email';
        } 

        // Password Validation
        if(account.password === ''){
          errors.errPasword = 'Password is required field';
        }else if(!verifyPassword(account.password)){
          errors.errPasword = 'Please enter password between 6 to 15 character';
        } 
        setErrors(errors);

        return Object.keys(errors).length === 0 ? null : errors;

      }

    const handleSubmit = e => {

        e.preventDefault();

        let email =account.email; 
        let password = account.password;
        
        if( validate() === null){
            setLoader(true);

            http.post(process.env.REACT_APP_API_URL+'/login', { email , password })
            .then((response) => { 
                setLoader(false);   
                toast.success(response.data.meta.message);
                localStorage.setItem('marketAuthToken',response.data.meta.auth_token); 
                // window.location.href = process.env.PUBLIC_URL
                props.setUserData(response.data.data,response.data.meta.auth_token)
                props.history.push(process.env.PUBLIC_URL+"/news")
               
            })
            .catch((error) => {
              setLoader(false);
              if(error.response && error.response.status != 200){
                 toast.error(error.response.data.meta.message);
              }
            });
        }
       
    }
    
    const handleChange = (e) => {

        const { name , value  } = e.target;
        account[name]=value;
        setAccount(account);
    }

    /* Register User */

    const [ registerData , setregisterData ] = useState( { s_term:0,s_fullname : '', s_email: '',s_email_confirm:'',s_password:'',s_password_confrim:'',s_subscription:'' } );
    const [ registerErrors , setregisterErrors] = useState( { err_s_term:'',err_s_fullname : '',err_s_email : '',err_s_email_confirm: '',err_s_password : '', err_s_password_confrim:'',err_s_subscription:''} );

    const validate_register = () => {
           
        const registerErrors = {};

        //Full Nme 
        if(registerData.s_fullname === ''){
          registerErrors.err_s_fullname = 'Full name is required field';
        }

         // Email Validation
        if(registerData.s_email === ''){
          registerErrors.err_s_email = 'Email is required field';
        }else if(!verifyEmail(registerData.s_email)){
          registerErrors.err_s_email = 'Please enter valid email';
        } 

        //Confirm Email
        if(registerData.s_email_confirm === ''){
          registerErrors.err_s_email_confirm = 'Confirm email is required field';
        }else if(!verifyEmail(registerData.s_email_confirm)){
          registerErrors.err_s_email_confirm = 'Please enter valid confirm email';
        }else if( registerData.s_email != registerData.s_email_confirm){
          registerErrors.err_s_email_confirm = 'Please enter confirm email same as email';
        }  

        // Password Validation
        if(registerData.s_password === ''){
          registerErrors.err_s_password = 'Password is required field';
        }else if(!verifyPassword(registerData.s_password)){
          registerErrors.err_s_password = 'Please enter password between 6 to 15 character';
        } 

        //Confirm Email
        if(registerData.s_password_confrim === ''){
          registerErrors.err_s_password_confrim = 'Confirm password is required field';
        }else if(!verifyPassword(registerData.s_password_confrim)){
          registerErrors.err_s_password_confrim = 'Please enter password between 6 to 15 character';
        }else if( registerData.s_password != registerData.s_password_confrim){
          registerErrors.err_s_password_confrim = 'Please enter confirm password same as password';
        }

        if(registerData.s_subscription === ''){
          registerErrors.err_s_subscription = 'Subsription is required field';
        }

        if(registerData.s_term === 0 || registerData.s_term === ""){
          registerErrors.err_s_term = 'Please accept term and condition';
        }

        setregisterErrors(registerErrors);

        return Object.keys(registerErrors).length === 0 ? null : registerErrors;

    }

    const register = e => {

        
        e.preventDefault();       
        
        if( validate_register() === null){

            let full_name =registerData.s_fullname; 
            let email = registerData.s_email;
            let email_confirmation = registerData.s_email_confirm;
            let password = registerData.s_password;
            let password_confirmation = registerData.s_password_confrim;
            let subsctiption_id = registerData.s_subscription;

            setLoader(true);
            http.post(process.env.REACT_APP_API_URL+'/validate-user', { full_name ,email,email_confirmation,password,password_confirmation,subsctiption_id })
            .then((response) => { 
                setLoader(false);   
                setModal1(false)
                setModal2(true)
            })
            .catch((error) => {
              setLoader(false);
              if(error.response && error.response.status != 200){
                 toast.error(error.response.data.meta.message);
              }
            });
            
        }
       
    }

    const handleChangeReg = (e) => {

        const { name , value  } = e.target;
        const regD = { ...registerData };
        regD[name]=value;
        setregisterData(regD);

    }

    const handleChangePlan = (e) => { 
        const regD = { ...registerData };
        regD['s_subscription']=e.currentTarget.value;
        setregisterData(regD);

        if(e.currentTarget.selectedOptions[0].getAttribute("days")){
          const days = e.currentTarget.selectedOptions[0].getAttribute("days");
          const amount = e.currentTarget.selectedOptions[0].getAttribute("amount");
          var amt = amount.split(".");
          
          const newAmt = { ...subValue };
          newAmt['days'] = days;
          newAmt['amt1'] = amt[0];
          newAmt['amt2'] = amt[1];
          setsubValue(newAmt);
        }
       

    } 

    const handleChecked = (e) => { 
        const newArr = { ...registerData }
        newArr['s_term']=e.currentTarget.checked == true ? 1 : 0 ;
        setregisterData(newArr);

    }

    return (
         <Fragment>

         <Helmet>
          <title>{ TITLE }</title>
        </Helmet>

         {loader && <Loader />} 

         {/* Header */}
             
         <Navigation />
 
         {/* Header */}
            
        {/* Login Section */}
        
        <div className="login-section">
            <Container>
                <div className="login-details">
                    <h2 className="center-title">User Login</h2>
                    <Form className="login-form" onSubmit= { handleSubmit }>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <label>EMAIL ADDRESS</label>
                                    <Input type="email" name="email" onChange={ handleChange } />
                                    { errors.errEmail && <p className="error-text"> <em> { errors.errEmail } </em></p> }
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label>YOUR PASSWORD</label>
                                    <Input type="password" name="password" onChange={ handleChange } />
                                    { errors.errPasword && <p className="error-text"> <em> { errors.errPasword }  </em></p> }  
                                    <Link to={`${process.env.PUBLIC_URL}/forgot-password`} className="forget-link" >Forgot?</Link>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col md={12}>
                            <div className="login-btns">
                                <Button>LOG ME IN</Button>
                                <div className="remember-checkbox">
                                    <input type="checkbox" name="remember-input" />
                                    <span><i class="fa fa-check" aria-hidden="true"></i></span>
                                    <p>Remember me</p>
                                </div>
                            </div>
                        </Col>
                    </Form>                                           
                </div>
            </Container>
        </div>

        {/* Login Section */}
 
         {/* Footer */}
 
         <FooterSection/>         
 
         {/* Footer */}
 
 
         </Fragment>)
}
const mapStateToProps = {
};


const mapDispatchToProps = dispatch => ({
  setUserData: (data,token) => dispatch({ type:'USER_PROFILE' , payload: { data: data,token } })
});

export default compose(
  withRouter,
  connect('', mapDispatchToProps)
)(Login);
