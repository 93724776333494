import React, { Component, Fragment ,useState,useEffect,useMemo } from 'react'
import { Helmet } from 'react-helmet'

// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;
import { connect } from 'react-redux';
// subscription page images
import aboutvideo from "../assets/videos/about-video.mp4";
// subscription page images
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
    NavLink,
    Button, Modal, ModalBody,FormGroup
  } from 'reactstrap';
import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import InnerMenu from './userLinks.jsx';
import http from '../httpService/httpService'
import { ToastContainer,toast } from 'react-toastify';
import Loader from '../components/loader.jsx';
import stripimg from "../assets/images/strip-img.png"
import Player from './Player.jsx';
import loadPlayer from './loadPlayer.js';

const TITLE = 'Subscription | Trading Floor Audio'
const Suscription   = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [ subscription_item , setSubscription_item] = useState([]);  
    const [ loader , setLoader] = useState(false);  

    // Update Subscription  ---------- Start 
    const [ modal2, setModal2] = useState(false);  
    const [loaded, setLoaded] = useState(true);
    const [endAt, setEndat] = useState('1 March 2021');
    

    useEffect(() => {
        if(props.userData.dispatchType === 'USER_PROFILE' && props.userData.token === localStorage.getItem('marketAuthToken')){
            
            if(props.userData.ends_at !== undefined)
              setEndat(props.userData.ends_at);
        }
    }, [props.userData])

    // Update Subscription  ---------- End
    return (
         <Fragment>
          <Helmet>
            <title>{ TITLE }</title>
          </Helmet>
         {loader && <Loader />} 

         <ToastContainer/>

         {/* Header */}
             
         <Navigation/>
 
         {/* Header */}
            
         {/* Dashboard-header */}

         <div className="dashboard-header-sec">
            <div className="dashboard-header">
              {loaded ? <Player /> : ''}              
            </div>
            <div className="dahboard-nav-sec">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                        <Col md="6">
                            <InnerMenu/>
                        </Col>
                        <Col md="3">
                            <div className="white-line"></div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <video autoPlay loop="" muted="muted" data-origwidth="0" data-origheight="0"  loop>
                    <source src={aboutvideo} type="video/mp4" />
             </video>
        </div>

        {/* Dashboard-header */}

        {/* Subscription-section */}

        <div className="subscription-section">
             <Container>  
                 <h2>My Subscription</h2>
                 <div className="subscription-details">
                    <div className="signup-plan-sec">
                        <span className="subscription-subtitle">LIVE SUBSCRIPTION</span>
                        <div className="signup-plan-details">
                            
                            <h3>£150<span>.00</span></h3>
                            <p>Per month</p>
                        </div>
                        <p className="end-subscription">Subscription Ends on : <span>{endAt}</span></p>
                    </div>
                    <div className="sunscription-desc">
                        <h6>Manage your Account Subscription</h6>
                        <p>Your Live Subscription will renew automatically after the initial period has come to an end. You have the option of updating your subscription on this page. Note that any changes that you make will kick-in at the end of the initial period agreed. Finally, you can choose to cancel your subscription, which will prevent account to be renewed.</p>
                    </div>
                 </div>
             </Container>
        </div>

        {/* Subscription-section */}
 
        {/* Footer */} 
        <FooterSection/>  
        {/* Footer */} 
 
    </Fragment>)
}
 const mapStateToProps = state => ({
  userData: state.auth,
})
 export default connect(mapStateToProps)(Suscription)
 // export default Suscription