import React,{useState,useRef,useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    TabContent, TabPane,
  } from 'reactstrap';
import { Container,Row, Col,Modal, ModalBody  } from 'reactstrap';
import { Link } from 'react-router-dom'
// common images
import logo from '../logo.svg';
import { connect } from 'react-redux';
import http from '../httpService/httpService'
import { history } from '../index'
// common images;
const Navigation = (props) =>{

    const location = useLocation();

    useEffect(() => {
        if(props.userData.dispatchType === 'USER_PROFILE' && props.userData.token === localStorage.getItem('marketAuthToken')){
            setisLogin(true)
        }
    }, [props.userData])

    const isFirstRun = useRef(true);
    useEffect (() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if(props.userData.dispatchType === 'USER_PROFILE' && props.userData.token === localStorage.getItem('marketAuthToken')){
          setisLogin(true)  
        }
    }, [props.userData]);

    const handleLogout = () => {
        setisLogin(false) 
        const token = localStorage.getItem('marketAuthToken');
        localStorage.removeItem('marketAuthToken')
        http.post(process.env.REACT_APP_API_URL + '/logout', {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then((response) => {      
          history.push(process.env.PUBLIC_URL + '/login');

        }).catch((error) => {        
           history.push(process.env.PUBLIC_URL + '/login');
        });

        return;
    }

    const redirectPage = (redTo,id) => {
        
        var itemArr = [process.env.REACT_APP_PREFIX+"news",process.env.REACT_APP_PREFIX+"subscription",process.env.REACT_APP_PREFIX+"settings"];
       
        if( itemArr.indexOf(location.pathname) > -1){
            setModal(true);
            setredToPage(redTo);
        }else{

            if(props.onClickMenu !== undefined && id != ''){
                props.onClickMenu(id);
            }else{
                window.location.href = redTo;
            }
            // console.log(location.pathname);
            //window.location.href = redTo;
            
        }
        

    }

    const [isOpen, setIsOpen] = useState(false);
    const [isLogin, setisLogin] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    
    const pagemodal = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const [redToPage, setredToPage] = useState(`${process.env.PUBLIC_URL}`);

    return (
        <header>
         <Container>
             <Navbar expand="md">
                 <Link className="navbar-brand" to={`${process.env.PUBLIC_URL}`}><img src={logo}/></Link>
                 <NavbarToggler onClick={toggle} className={isOpen == true ? "toggle-btn-open" : ""} >
                    <span></span>
                    <span></span>
                    <span></span>
                </NavbarToggler>
                 <Collapse isOpen={isOpen} navbar>
                 <Nav className="ml-auto" navbar>
                     <NavItem>                         
                         <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#about`,'about')}>ABOUT</NavLink>
                     </NavItem>
                    {/* <NavItem>
                         <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#pricing`,'pricing')} >PRICING</NavLink>
                     </NavItem> */}
                     <NavItem>
                         <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#blog`,'blog')}>BLOG</NavLink>
                     </NavItem>
                     <NavItem>
                         <NavLink onClick={ (e) => redirectPage(`${process.env.PUBLIC_URL}/#contact`,'contact')} >CONTACT</NavLink>
                     </NavItem>
                     <NavItem className="login-link">
                        { isLogin === true ? <Link to="#" onClick={handleLogout} >LOGOUT </Link> : <Link to={`${process.env.PUBLIC_URL}/login`}>LOGIN</Link> }                         
                     
                     </NavItem>
                 </Nav>
                 </Collapse>
             </Navbar>
           </Container>
           <Modal isOpen={modal} toggle={pagemodal} className="page-modal" >
                <ModalBody>
                    <h3>Are You Sure ?</h3>
                    <p>Are you sure you want to navigate away from this page? Leaving will STOP your in-page audio-connection... </p>
                     <NavLink className="primary-btn btn" href={ redToPage }> Leave Page Anyway</NavLink>
                    <button className="primary-btn btn" onClick={pagemodal} >Stay on This Page</button>
                </ModalBody>
            </Modal>
         </header>
    )
}

const mapStateToProps = state => ({
  userData: state.auth,
})
export default connect(mapStateToProps)(Navigation)