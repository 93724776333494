import React, { Component, Fragment ,useState,useEffect } from 'react'
import http from '../httpService/httpService'
import { ToastContainer,toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css'
import loadPlayer from './loadPlayer.js';
import { Link } from 'react-router-dom'

const Player = (props) => {
	const [ expired , setExpired ] = useState(1);
	const [ loaded, setLoaded] = useState(false);
	useEffect(() => {        
        
        if(!props.fetched) {
            const token = localStorage.getItem('marketAuthToken');
            http.get(process.env.REACT_APP_API_URL + '/user-view-profile',{
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then((response) => {
            	setExpired(response.data.data.expired);
            	loadPlayer(); 
            	if(response.data.data.expired == 1){
            		toast.error('Your subscription is expired. Please contact admin...');
            	}                           
            });
        }        

    }, []);

    return (
        <>
        <Fragment>
        { expired == 0 ? <div id="livesquawk_player_outer">
            </div> : '' }  
                   
        </Fragment> 
           
        </>
    )

};
export default Player;