import React, { Component, Fragment ,useState,useEffect,useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

// common component & css
import 'reactstrap/dist/reactstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';

import '../assets/scss/style.css';
import '../assets/css/font-awesome.css'
import { Container,Row, Col } from 'reactstrap';
// common component & css

// common images
import logo from '../logo.svg';
import footerlogo from "../assets/images/footer-logo.svg";
// common images;

// Home Page component & css

import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import classnames from 'classnames';
// Home Page component & css


// Hoem Page images
import featurecost from "../assets/images/feature-cost.svg";
import featureinstant from "../assets/images/feature-instant.svg";
import featurenocontract from "../assets/images/feature-no-contract.svg";
import aboutimg from "../assets/images/about-img.png";
import aboutbg from "../assets/images/about-bg.jpeg";
import aboutvideo from "../assets/videos/about-video.mp4";
import aboutreaserch from "../assets/images/about-reaserch.png";
import aboutteam from "../assets/images/about-team.png";
import spotifylogo from "../assets/images/spotify-logo.png";
import companyslidebg from "../assets/images/compnay-slide-bg.jpg";
import blogbg from "../assets/images/blog-bg.png"
import samplebg from "../assets/images/sample-bg.png";
import videobg from "../assets/images/video-bg.jpg";
import { connect } from 'react-redux';
// Hoem Page images

import Navigation from './nav.jsx';
import FooterSection from './footerSection.jsx';
import http from '../httpService/httpService'
import { ToastContainer,toast } from 'react-toastify';
import { verifyEmail } from '../helper';
import Loader from '../components/loader.jsx';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	TabContent, TabPane,
	Button, Form, FormGroup, Input,  Modal, ModalBody
  } from 'reactstrap';

  var companyslider = {
	dots: true,
	arrows:false,
	infinite: true,
	speed: 2000,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay:true,
	autoplaySpeed:4000,
	pauseOnHover:false,
	slide: 'div'
  };
  var blogslider = {
	dots: false,
	arrows:true,
	infinite: false,
	speed: 1000,
	slidesToShow: 4,
	slidesToScroll: 1,
	responsive: [
		{
		  breakpoint: 1199,
		  settings: {
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: true,
		  }
		},
		{
		  breakpoint: 991,
		  settings: {
			slidesToShow: 2,
			slidesToScroll: 1,
			infinite: true,
		  }
		},
		{
		  breakpoint: 576,
		  settings: {
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
		  }
		}
	  ]
};
var parse = require('html-react-parser');

const TITLE = 'Home | Trading Floor Audio'
var parse = require('html-react-parser');
const Home   = (props) => {

		useEffect(() => {
	        if(props.userData.dispatchType === 'USER_PROFILE' && props.userData.token === localStorage.getItem('marketAuthToken')){
	            setisLogin(true)
	        }
	    }, [props.userData])

	    const isFirstRun = useRef(true);
	    useEffect (() => {
	        if (isFirstRun.current) {
	            isFirstRun.current = false;
	            return;
	        }
	        if(props.userData.dispatchType === 'USER_PROFILE' && props.userData.token === localStorage.getItem('marketAuthToken')){
	          setisLogin(true)  
	        }
	    }, [props.userData]);

	    const [isLogin, setisLogin] = useState(false);
		const [isOpen, setIsOpen] = useState(false);
		const toggle = () => setIsOpen(!isOpen);
		const videomodal = () => setModal(!modal);
		const [ subscription_item , setSubscription_item] = useState([]);
		const [ ads_item , setAdsItem] = useState([]);
		const [ latest_blog_item , setLatestBlogItem] = useState([]);
		const [ popular_blog_item , setPopularBlogItem] = useState([]);
		const [ country_items , setCountryItem] = useState([]);

		const [activeTab, setActiveTab] = useState('1');
		const [activeOption, setActiveOption] = useState('1');
		const [modal, setModal] = useState(false);

		// Home Content
		const [homeBannerContent, sethomeBannerContent] = useState({ 
			title_1 : 'Financial Market News and Information Service',
			title_2 : 'Trading Floor Audio',
			description_1 : 'For traders and investors needing live financial market commentary. Find out why the markets move in Real-Time',
		});

		const [aboutUs, setAboutUs] = useState({ 
			short_description : 'Get live 24-hour real-time audio financial market news and analysis. Delivered clearly and succinctly.',
			title_1 : 'TRADING FLOOR AUDIO',
			image_1 : aboutteam,
			description_1 : 'Trading Floor Audio has been providing an unparalleled service to a global client base since 2005. From investments banks, hedge funds and IDB’s through to trading arcades and independent traders, we have consistently achieved a high level of accuracy, navigating our customers through some of the financial markets most turbulent times.',
			title_2 : 'THE RESEARCHERS',
			image_2 : aboutreaserch,
			description_2 : 'The TFA team scrutinise all the major newswires, television channels, websites, blogs and social media platforms for content with market-moving potential. Our team provide the most important and impactful intel straight to your PC, laptop or mobile device. In addition, in house written reports, analysis, scrolling news and technical data are produced and delivered 24-hours a day covering Asian, European, and American markets.',
		});

		const [getInTouch, setGetInTouch] = useState({ 
			short_description_2 : 'To enquire about a free trial of Trading Floor Audio or simply for more information on the products available get in touch',			
		});

		const [ourPricing, setOurPricing] = useState({ 
			short_description_1 : "Sign up for a 'FREE TRIAL' and listen to a 30-year plus market professional with invaluable insights and commentary on the market moving headlines of the day.",			
		});

		const toggletab = tab => {			
			if(activeTab !== tab) setActiveTab(tab);
		}

		const toggleOption = event => {					
			if(activeOption !== event.target.value) setActiveOption(event.target.value);
		}

   		useEffect(() => {
	        if(!props.fetched) {

	        	http.get(process.env.REACT_APP_API_URL + '/home-content')
	            .then((response) => {
	                sethomeBannerContent(response.data.data.key_features);
	                setAboutUs(response.data.data.about_us);
	                setGetInTouch(response.data.data.get_in_touch);
	                setOurPricing(response.data.data.our_pricing);
	            });

	            http.get(process.env.REACT_APP_API_URL + '/blog-list?offset=0&limit=20&filter_by=latest')
	            .then((response) => {
	                setLatestBlogItem(response.data.data);
	            });

	            http.get(process.env.REACT_APP_API_URL + '/blog-list?offset=0&limit=20&filter_by=popular')
	            .then((response) => {
	                setPopularBlogItem(response.data.data);
	            });

	            http.get(process.env.REACT_APP_API_URL + '/country-list')
	            .then((response) => {
	                setCountryItem(response.data.data);
	            });
	        }
	    }, []);


	    //Contact Form
	    const initContactState = { fname : '',lname : '',company : '', email: '',telephone: '',address:'',city:'',country:'1',message: '',service:'' };
	    const [ contactDetail , setContactDetail ] = useState(initContactState);
    	const [ errors , setErrors] = useState( { errFname : '',errLname : '',errCompany:'',errEmail:'',errTelephone:'',errAddress:'',errCity:'',errCountry:'',errService:'' } );
    	const [ loader , setLoader] = useState(false);

    	const validate = () => {
           
	        const errors = {};
	        // Name Validation
	        if(contactDetail.fname == ''){
	          errors.errFname = 'First name is required field';
	        }

	        if(contactDetail.lname == ''){
	          errors.errLname = 'Last name is required field';
	        }

	        // Email Validation
	        if(contactDetail.email == ''){
	          errors.errEmail = 'Email is required field';
	        }else if(!verifyEmail(contactDetail.email)){
	          errors.errEmail = 'Please enter valid email';
	        } 

	        // Email Validation
	        if(contactDetail.telephone == ''){
	          errors.errTelephone = 'Telephone number is required field';
	        }

	        // City Validation
	        if(contactDetail.city == ''){
	          errors.errCity = 'City is required field';
	        }

	        // City Validation
	        if(contactDetail.country == ''){
	          errors.errCountry = 'Country is required field';
	        }

	        // Message Validation
	        if(contactDetail.message == ''){
	          errors.errMessage = 'Message is required field';
	        } 

	        setErrors(errors);
	        return Object.keys(errors).length === 0 ? null : errors;

	    }

	    const handleSubmit = e => {
	        e.preventDefault();
	        let fname =contactDetail.fname; 
	        let lname =contactDetail.lname; 
	        let company =contactDetail.company; 
	        let email =contactDetail.email; 
	        let telephone =contactDetail.telephone; 
	        let address =contactDetail.address; 
	        let city =contactDetail.city; 
	        let country =contactDetail.country; 
	        let message =contactDetail.message; 
	        let service =  cservice.join(", "); ; 
	       	let gc = localStorage.getItem('googleCaptchaToken') ?? '';

	        if( validate() === null){
	            setLoader(true);

	            http.post(process.env.REACT_APP_API_URL+'/contact-us', { fname , lname, company ,email,telephone,address,city,country,message,service,gc})
	            .then((response) => {   

	                document.getElementById("contact-form").reset();
	                setLoader(false);      
	                toast.success(response.data.meta.message);
	                setContactDetail(initContactState);
	                setService([]);

	                setTimeout(function(){
					   window.location.reload(1);
					}, 2000);
					
	            })
	            .catch((error) => {
	              setLoader(false);
	              if(error.response && error.response.status != 200){
	                 toast.error(error.response.data.meta.message);
	              }
	            });
	        }
	       
	    }
	    
	    const handleChange = (e) => {

	        const { name , value  } = e.target;
	        contactDetail[name]=value;
	        setContactDetail(contactDetail);
	    }

	    const cleanDesc = (data) => {
	    	
	    	var data1 =  data.replace(/<[^>]+>/g, '').slice(0,50);
	    	return parse(data1);
	    }


	    const [siteConf, setSiteConf] = useState({ 
	        email : 'info@tradingfloor.com',
	        contact : '0203 753 4648',
	        address : 'London Head Office: Unit 414, Coppergate House,10 Whites Row,London E1 7NF'
	    });

	    useEffect(() => {
	        if(!props.fetched) {

	            http.get(process.env.REACT_APP_API_URL + '/site-config')
	            .then((response) => {	            	
	               setSiteConf({
	                   email : response.data.data[1].support_email,
	                   contact : response.data.data[2].support_contact,
	                   address : response.data.data[3].support_address
	               });
	            });
	        }

	        if(!props.fetched) {
	        	var type = window.location.hash.substr(1);
	        	if(type != undefined && type != '' && type!='#'){
	        		if(document.getElementById(type))
	        			document.getElementById(type).scrollIntoView({ behavior: "smooth" });
	        	}
	       	}
	    }, []);


	    const handleMenuClick = (id) => {
	    	if(document.getElementById(id)){
	    		document.getElementById(id).scrollIntoView({ behavior: "smooth" });
	    	}
		    
		};
		const handleChangeCountry = (e) => {

	        const { name , value  } = e.target;
	        contactDetail['country']=value;
	        setContactDetail(contactDetail);
	    }

	    const [ cservice , setService] = useState([]);
	    const handleServiceClick = (e) => {	       
	    	if(e.target.checked == true){
	    		let value = e.target.value;
		        cservice.push(value);	
		        setService(cservice); 	        
		    }else{		        
		        let value = e.target.value;
		        let ss = cservice.filter(fl => fl != value);
		        setService(ss); 		        
		    }		   
	    }


	return (
		<Fragment>

		<Helmet>
          <title>{ TITLE }</title>
        </Helmet>
		{/* Header */}			

		{loader && <Loader />} 
		<Navigation onClickMenu= { handleMenuClick } />

		{/* Header */}	

		{/* Banner Section */}

		<div className="home-banner-sec">
			<Container>
				<Row>
					<Col md="7">
						<div className="banner-content">
							<h6>A '{ homeBannerContent.title_1}'</h6>
							<h1>{homeBannerContent.title_2}</h1>
							{/* <p>For traders and investors needing a live financial market newscast. <strong>Find out why the markets move in <span>Real-Time</span></strong></p> */}
							<p>{ parse(homeBannerContent.description_1)}</p>
							<div className="banner-btns">
								{ isLogin == true ? 
									<Link to={`${process.env.PUBLIC_URL}/settings`} className="common-btn sign-up-btn btn nav-link">Dashboard</Link>
									:
									<Link to={`${process.env.PUBLIC_URL}/login`} className="common-btn sign-up-btn btn nav-link">SIGN ME UP</Link>
								}
								
								<NavLink href="#" className="primary-btn btn" onClick={videomodal}>PLAY VIDEO</NavLink>

							</div>
						</div>
					</Col>
					<Col md="5">
						<div className="banner-video" style={{ backgroundImage: 'url(' + videobg + ')' }}>
							<a className="video-link" onClick={videomodal}></a>
						</div>
						<Modal isOpen={modal} toggle={videomodal} className="video-modal" >
							<ModalBody>
								<iframe src= {`${process.env.REACT_APP_BACKENDURL}homepage-video.mp4`} width="100%" height="100%" frameborder="0" allowFullScreen></iframe>
								{/* <video>
									<source src={aboutvideo} type="video/mp4" />
								</video> */}
								<a className="close-modal-link"  onClick={videomodal}><i className="fa fa-times-circle" aria-hidden="true"></i></a>
							</ModalBody>
						</Modal>
					</Col>
				</Row>
			</Container>
		</div>

		{/* Banner Section */}

		{/* About Section */}

		<div className="about-us-section" id="about">
			<Container>
				<Row>
					{/* <Col md="2"></Col> */}
					<Col md="12">
						<div className="about-left-sec">
							<h2>About us</h2>
							<p>{ aboutUs.short_description }</p>
							<ul className="about-list">
								<li>
									<div className="about-list-img">
										<img src={ aboutUs.image_1 } alt="aboutteam"/>
									</div>
									<div className="about-list-desc">
										<p><span className="about-list-title">{ aboutUs.title_1 }</span>{ aboutUs.description_1 }</p>
									</div>
								</li>
								<li>
									<div className="about-list-img">
										<img src={ aboutUs.image_2 } alt="aboutreaserch"/>
									</div>
									<div className="about-list-desc">
										<p><span className="about-list-title">{ aboutUs.title_2 }</span>{ aboutUs.description_2 }</p>
									</div>
								</li>
							</ul>
						</div>
					</Col>
					{/* <Col md="4">
						<div className="about-right-sec">
							<img src={aboutimg} alt="aboutimg"/>
						</div>
					</Col> */}
				</Row>
			</Container>
			{/* <video  loop="" muted="muted" data-origwidth="0" data-origheight="0" loop autoPlay>
                    <source src={aboutvideo} type="video/mp4" />
             </video> */}
		</div>

		{/* About Section */}

								
		{/* Pricing Section */}

		<div className="pricing-section" id="pricing">
			<Container>
				<Row>
					{/* <Col md="2">		
					</Col> */}
					<Col md="12">
						{/*  <h2>Our Pricing</h2>*/}
						<div className="pricing-desc">
							<p>{ ourPricing.short_description_1 }</p>
						</div>						
						{/* <ul className="pricing-list">
							<li>								
								<h3 className="price">£150<span>.00</span></h3>							
								<p className='permonth'>Per Month</p>
								<Link onClick={ (e) => handleMenuClick('contact')} className="buy-now-btn">BUY NOW</Link>
							</li>
						</ul> */}
					</Col>
				</Row>
			</Container>
		</div>


		{/* Pricing Section */}

		

		{/* Blog & Contact Section */}

		<div className="blog-and-contact-sec" id="blog">

			<Container>

				{/* Blog Section */}

				<div className="blog-section">
					<h2>Our Blog</h2>

					{/* For Desktop Nav tabs */}
					<div className="blog-tab-section">
						<Nav tabs>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === '1' })}
									onClick={() => { toggletab('1'); }}
								>
									LATEST
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === '2' })}
									onClick={() => { toggletab('2'); }}
								>
									POPULAR
								</NavLink>
							</NavItem>							
						</Nav>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<Slider {...blogslider} className="blog-slider">

									{ 
										latest_blog_item.map((lbItem,k3) => 
											<div id={ "b1_"+k3 }>
												<div className="blog-slide">
													<div className="blog-img" style={{ backgroundImage: 'url(' + lbItem.image + ')' }}>
													</div>
													<div className="blog-slide-desc">
														<h6>{ parse(lbItem.title.slice(0,20))}</h6>
														{/* parse(lbItem.description) */}
														<p>{  cleanDesc(lbItem.description) }</p>
														<Link to={`${process.env.PUBLIC_URL}/blog-details/${lbItem.slug} `}  className="read-more-link">Read More</Link>
													</div>
												</div>
											</div>
										)
									}
									
								</Slider>
							</TabPane>
							<TabPane tabId="2">
								<Slider {...blogslider} className="blog-slider">
									{ 
										popular_blog_item.map((pbItem,k4) => 
											<div id={ "b2_"+k4 }>
												<div className="blog-slide">
													<div className="blog-img" style={{ backgroundImage: 'url(' + pbItem.image + ')' }}>
													</div>
													<div className="blog-slide-desc">
														<h6>{ parse(pbItem.title.slice(0,20))}</h6>
														{/* parse(pbItem.description) */}
														<p>{  cleanDesc(pbItem.description) }</p>
														<Link to={`${process.env.PUBLIC_URL}/blog-details/${pbItem.slug} `} className="read-more-link">Read More</Link>
													</div>
												</div>
											</div>
										)
									}
								</Slider>
							</TabPane>
						</TabContent>
					</div>	

					{/* For Desktop Nav tabs */}

					{/* For Mobile Nav Dropdown */}

					<div className="blog-drp-section">
						 <div className="blog-drpdown-list">
							<select onChange={ toggleOption }>
								<option value="1" >LATEST</option>
								<option value="2" >POPULAR</option>								
							</select>			
						 </div>
						 
						
						 <Slider {...blogslider} className="blog-slider" style={{ display: activeOption == 1 ? 'block' : 'none' }}>
							{ 
								latest_blog_item.map((lbItem,k5) =>
									<div id={ "b5_"+k5 }>
										<div className="blog-slide">
											<div className="blog-img" style={{ backgroundImage: 'url(' + lbItem.image + ')' }}>
											</div>
											<div className="blog-slide-desc">
												<h6>{ parse(lbItem.title.slice(0,20)) }</h6>
												<p>{  cleanDesc(lbItem.description) }</p>
												<Link to={`${process.env.PUBLIC_URL}/blog-details/${lbItem.slug} `} className="read-more-link">Read More</Link>
											</div>
										</div>
									</div>
								)
							}						
						</Slider>
						 <Slider {...blogslider} className="blog-slider" style={{ display: activeOption == 2 ? 'block' : 'none' }}>
							{ 
								popular_blog_item.map((pbItem,k6) =>
									<div id={ "b6_"+k6 }>
										<div className="blog-slide">
											<div className="blog-img" style={{ backgroundImage: 'url(' + pbItem.image + ')' }}>
											</div>
											<div className="blog-slide-desc">
												<h6>{ parse(pbItem.title.slice(0,20)) }</h6>
												<p>{  cleanDesc(pbItem.description) }</p>
												<Link to={`${process.env.PUBLIC_URL}/blog-details/${pbItem.slug} `} className="read-more-link">Read More</Link>
											</div>
										</div>
									</div>
								)
							}						
						</Slider>
						

					</div>

					{/* For Mobile Nav Dropdown */}

				</div>

	
				{/* Blog Section */}

				{/* Contact Section */}

				<div className="contact-section" id="contact">
					<h2>Get in touch</h2>
					<div className="contact-desc">
						<p>{ getInTouch.short_description_2 }</p>
					</div>
					<Row>
						<Col md="7">
							<Form className="contact-form" id="contact-form" onSubmit= { handleSubmit }>
								<Row>
									<Col md={6}>
										<FormGroup>
											<Input type="text" name="fname" id="fname"  placeholder="First Name*" onChange={ handleChange } />
											{ errors.errFname && <p className="error-text"> <em> { errors.errFname } </em></p> }
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Input type="text" name="lname" id="lname" placeholder="Last Name*" onChange={ handleChange } />
											{ errors.errLname && <p className="error-text"> <em> { errors.errLname } </em></p> }
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Input type="text" name="company"  placeholder="Company" onChange={ handleChange } />
											{ errors.errCompany && <p className="error-text"> <em> { errors.errCompany } </em></p> }
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Input type="email" name="email" id='email'  placeholder="Email*" onChange={ handleChange } />
											{ errors.errEmail && <p className="error-text"> <em> { errors.errEmail } </em></p> }
										</FormGroup>
									</Col>
									<Col md="6">
										<FormGroup>
											<Input type="text" name="telephone"  placeholder="Telephone*" onChange={ handleChange } />
											{ errors.errTelephone && <p className="error-text"> <em> { errors.errTelephone } </em></p> }
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Input type="text" name="address"  placeholder="Address" onChange={ handleChange } />
											{ errors.errAddress && <p className="error-text"> <em> { errors.errAddress } </em></p> }
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Input type="text" name="city"  placeholder="City*" onChange={ handleChange } />
											{ errors.errCity && <p className="error-text"> <em> { errors.errCity } </em></p> }
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup className="form-select">
											<select className="form-control" name='country' onChange={ handleChangeCountry }>
												{ country_items.map((cItem,ci) =>
													<option id={ "con_"+ci } value={cItem.id}>{cItem.name}</option>
												 )
												}
												
											</select>
										</FormGroup>
									</Col>
								</Row>
								
								<Row>
									<Col md="12">
										<FormGroup>
											<Input type="textarea" name="message" placeholder="Message*" onChange={ handleChange } />
											{ errors.errMessage && <p className="error-text"> <em> { errors.errMessage } </em></p> }
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<FormGroup className="form-chechbox">
											<label>Services</label>
											<div className="service-checkbox">
												<input type="checkbox" name="service[]"  value='Market Research' onClick={ handleServiceClick}/>
												<span><i class="fa fa-check" aria-hidden="true"></i></span>
												<p>Market Research</p>
											</div>
											<div className="service-checkbox">
												<input type="checkbox" name="service[]" value='Squawk Service' onClick={ handleServiceClick}/>
												<span><i class="fa fa-check" aria-hidden="true"></i></span>
												<p>Squawk Service</p>
											</div>
										</FormGroup>
									</Col>
								</Row>
								<Button id="zpay-now-btn">SEND</Button>
							</Form>
						</Col>
						<Col md="5">
							<ul className="contact-details">
								<li className="contact-mail">
									<a href={ "mailto:"+ siteConf.email }>{ siteConf.email }</a>
								</li>
								<li className="contact-tel">
									<a href={ "tel:"+ siteConf.contact }>{ siteConf.contact }</a>
								</li>
								<li className="contact-address">
									<p>{ parse(siteConf.address) }</p>
								</li>
							</ul>
						</Col>
					</Row>
					
				</div>

				{/* Contact Section */}

			</Container>

		</div>

		<div>
			
		</div>

		{/* Blog & Contact Section */}

		{/* Footer */}

		<FooterSection onClickMenu= { handleMenuClick }/>  
		

		{/* Footer */}


		</Fragment>)
	}

const mapStateToProps = state => ({
  userData: state.auth,
})
export default connect(mapStateToProps)(Home)